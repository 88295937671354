import {nr,ng,nb} from "./const.js"
import {
  Card,
  Pane,
  Button,
  Heading,
  majorScale,
  TickCircleIcon,
  DotIcon,
} from "evergreen-ui";
import { useState } from "react";
let tint = {
  Red: "redTint",
  Blue: "blueTint",
  Green: "greenTint",
};
function randint(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}
function MM({ color = "Blue", number = 1, selected = false }) {
  return (
    <Card
      float="left"
      width={100}
      height={60}
      margin={24}
      display="flex"
      justifyContent="center"
      alignItems="center"
      background={tint[color]}
      elevation={4}
    >
      <Pane
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Pane padding={6} display="flex" flexDirection="row">
          <Heading size={400}>{color}</Heading>
          <Heading size={400}>{number}</Heading>
        </Pane>
        <Pane padding={6}>
          {selected ? <TickCircleIcon size={20} /> : <DotIcon size={20} />}
        </Pane>
      </Pane>
    </Card>
  );
}
function makeCards(r,g,b){
    let ar=Array(r).fill(0).map( (_,i)=>({ color: "Red", number: i+1}) )
    let ag=Array(g).fill(0).map( (_,i)=>({ color: "Green", number: i+1}) )
    let ab=Array(b).fill(0).map( (_,i)=>({ color: "Blue", number: i+1}) )
    return ar.concat(ag,ab)
}
let nn=nr+ng+nb
let mmlist = makeCards(nr,ng,nb);
export default function MMS() {
  const [pick, setPick] = useState(-1);
  return (
    <Pane display="flex" flexDirection="column">
      <Pane flexDirection="row" padding={24}>
        {mmlist.map(({ color, number, selected }, i ) => (
          <MM color={color} number={number} selected={(pick===i) ? true:false } />
        ))}
      </Pane>
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="center"
        padding={24}
      >
        <Button
          justifyContent="center"
          height={majorScale(8)}
          onClick={() => setPick(randint(0, nn-1))}
        >
          <Heading>Pick a MM!</Heading>
        </Button>
        <Button
          justifyContent="center"
          height={majorScale(8)}
          onClick={() => setPick(-1)}
        >
          <Heading>Reset</Heading>
        </Button>
      </Pane>
    </Pane>
  );
}
